import { createBrowserRouter } from "react-router-dom";
import loadable from "@loadable/component";

//Utils
import { webRoutes } from "./web";

//Components
import AdEarning from "../components/home/pages/AdEarning";
import Services from "../components/home/pages/Services";
import OnlineShopping from "../components/home/pages/Shopping";
import Plans from "../components/home/pages/Plans";
import AboutUs from "../components/home/pages/About";
import Login from "../components/auth/Login";
import Signup from "../components/auth/Signup";
import ForgotPassword from "../components/auth/ForgotPassword";
import OTP from "../components/auth/OTP";
import ResetPassword from "../components/auth/ResetPassword";
import ErrorPage from "../components/error/ErrorPage";
import NotFoundPage from "../components/error/NotFoundPage";
import ProgressBar from "../components/loader/ProgressBar";
import Layout from "../components/layout";
import RequireAuth from "./RequireAuth";

//Load Pages
const Home = loadable(() => import("../components/home"), {
  fallback: <ProgressBar />,
});
const Dashboard = loadable(() => import("../components/dashboard"), {
  fallback: <ProgressBar />,
});
const Membership = loadable(() => import("../components/membership"), {
  fallback: <ProgressBar />,
});
const DailyAds = loadable(() => import("../components/daily-ads"), {
  fallback: <ProgressBar />,
});
const Subscription = loadable(() => import("../components/subscription"), {
  fallback: <ProgressBar />,
});
const Packages = loadable(() => import("../components/package"), {
  fallback: <ProgressBar />,
});
const NewMemberShip = loadable(() => import("../components/new_membership"), {
  fallback: <ProgressBar />,
});
const Accounts = loadable(() => import("../components/accounts"), {
  fallback: <ProgressBar />,
});
const Balance = loadable(() => import("../components/balance"), {
  fallback: <ProgressBar />,
});
const WithdrawalRequest = loadable(
  () => import("../components/withdraw_request"),
  {
    fallback: <ProgressBar />,
  }
);
const Withdrawals = loadable(() => import("../components/withdrawals"), {
  fallback: <ProgressBar />,
});
const Referral = loadable(() => import("../components/referral"), {
  fallback: <ProgressBar />,
});
const Notifications = loadable(() => import("../components/notifications"), {
  fallback: <ProgressBar />,
});
const WithdrawalProofs = loadable(
  () => import("../components/withdrawal_proofs"),
  {
    fallback: <ProgressBar />,
  }
);
const Profile = loadable(() => import("../components/profile"), {
  fallback: <ProgressBar />,
});
const Blogging = loadable(() => import("../components/blogging"), {
  fallback: <ProgressBar />,
});
const Shopping = loadable(() => import("../components/shopping"), {
  fallback: <ProgressBar />,
});

export const browserRouter = createBrowserRouter([
  {
    path: webRoutes.home,
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: webRoutes.adEarning,
    element: <AdEarning />,
    errorElement: <ErrorPage />,
  },
  {
    path: webRoutes.services,
    element: <Services />,
    errorElement: <ErrorPage />,
  },
  {
    path: webRoutes.OnlineShopping,
    element: <OnlineShopping />,
    errorElement: <ErrorPage />,
  },
  {
    path: webRoutes.InvestmentPlans,
    element: <Plans />,
    errorElement: <ErrorPage />,
  },
  {
    path: webRoutes.about,
    element: <AboutUs />,
    errorElement: <ErrorPage />,
  },

  //Auth Routes
  {
    errorElement: <ErrorPage />,
    children: [
      {
        path: webRoutes.login,
        element: <Login />,
      },
      {
        path: webRoutes.signup,
        element: <Signup />,
      },
      {
        path: webRoutes.forgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: webRoutes.verifyOTP,
        element: <OTP />,
      },
      {
        path: webRoutes.resetPassword,
        element: <ResetPassword />,
      },
    ],
  },

  //Protected Routes
  {
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: webRoutes.dashboard,
        element: <Dashboard />,
      },
      {
        path: webRoutes.membership,
        element: <Membership />,
      },
      {
        path: webRoutes.dailyAds,
        element: <DailyAds />,
      },
      {
        path: webRoutes.buySubscription,
        element: <Subscription />,
      },
      {
        path: webRoutes.buyPackages,
        element: <Packages />,
      },
      {
        path: webRoutes.blogging,
        element: <Blogging />,
      },
      {
        path: webRoutes.shopping,
        element: <Shopping />,
      },
      {
        path: webRoutes.newMembership,
        element: <NewMemberShip />,
      },
      {
        path: webRoutes.accounts,
        element: <Accounts />,
      },
      {
        path: webRoutes.balance,
        element: <Balance />,
      },
      {
        path: webRoutes.withdrawRequest,
        element: <WithdrawalRequest />,
      },
      {
        path: webRoutes.withdrawals,
        element: <Withdrawals />,
      },
      {
        path: webRoutes.referralLinks,
        element: <Referral />,
      },
      {
        path: webRoutes.notifications,
        element: <Notifications />,
      },
      {
        path: webRoutes.withdrawalsProofs,
        element: <WithdrawalProofs />,
      },
      {
        path: webRoutes.profile,
        element: <Profile />,
      },
    ],
  },

  // 404
  {
    path: "*",
    element: <NotFoundPage />,
    errorElement: <ErrorPage />,
  },
]);
