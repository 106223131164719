import axios from "axios";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  GET_STATISTICS_REQUEST,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from "../constants/authConstants";
import { BASE_URL } from "../constants/constants";

export const userSignup = (info) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({ type: USER_SIGNUP_REQUEST });
      const { data } = await axios.post(
        `${BASE_URL}/api/user/signup`,
        info,
        config
      );

      dispatch({ type: USER_SIGNUP_SUCCESS, payload: data });
    } catch (error) {
      console.log(error);
      dispatch({
        type: USER_SIGNUP_FAIL,
        payload: [error.response.data],
      });
    }
  };
};

export const userLogin = (info) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({ type: USER_LOGIN_REQUEST });
      const { data } = await axios.post(
        `${BASE_URL}/api/user/login`,
        info,
        config
      );
      localStorage.setItem("USER_TOKEN", data.token);
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response.data.errors);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: [error.response.data],
      });
    }
  };
};

export const userStatistics = () => {
  return async (dispatch, getState) => {
    const {
      UserLoginReducer: { token },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      dispatch({ type: GET_STATISTICS_REQUEST });
      const { data } = await axios.get(
        `${BASE_URL}/api/user/statistics`,
        config
      );

      dispatch({ type: GET_STATISTICS_SUCCESS, payload: data.data });
    } catch (error) {
      console.log(error.response.data.errors);
      dispatch({
        type: GET_STATISTICS_FAIL,
        payload: [error.response.data.errors],
      });
    }
  };
};

export const forgotPassword = (email) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });
      await axios.get(`${BASE_URL}/api/user/send-otp/${email}`, config);

      dispatch({ type: FORGOT_PASSWORD_SUCCESS });
    } catch (error) {
      console.log(error.response.data);
      dispatch({
        type: FORGOT_PASSWORD_FAIL,
        payload: [error.response.data],
      });
    }
  };
};

export const verifyOTP = (email, otp) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({ type: VERIFY_OTP_REQUEST });
      await axios.put(
        `${BASE_URL}/api/user/verify-otp`,
        { email, otp },
        config
      );

      dispatch({ type: VERIFY_OTP_SUCCESS });
    } catch (error) {
      console.log(error.response.data);
      dispatch({
        type: VERIFY_OTP_FAIL,
        payload: [error.response.data],
      });
    }
  };
};

export const resetPassword = (email, password, confirmPassword) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({ type: RESET_PASSWORD_REQUEST });
      await axios.put(
        `${BASE_URL}/api/user/forgot-password`,
        { email, password, confirmPassword },
        config
      );

      dispatch({ type: RESET_PASSWORD_SUCCESS });
    } catch (error) {
      console.log(error.response.data);
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: [error.response.data],
      });
    }
  };
};
