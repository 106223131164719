import {
  BiHomeAlt2,
  BiUser,
  BiMoney,
  BiBasket,
  BiAward,
  BiNotification,
  BiLinkExternal,
  BiWallet,
  BiHistory,
  BiMoneyWithdraw,
  BiCart,
} from "react-icons/bi";
import { MdVideoLibrary } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import Icon from "@ant-design/icons";

//Components
import { webRoutes } from "../../routes/web";

export const sidebar = [
  {
    path: webRoutes.dashboard,
    key: webRoutes.dashboard,
    name: "Dashboard",
    icon: <Icon component={BiHomeAlt2} />,
  },
  {
    path: webRoutes.membership,
    key: webRoutes.membership,
    name: "Membership",
    icon: <Icon component={BiAward} />,
  },
  {
    path: webRoutes.dailyAds,
    key: webRoutes.dailyAds,
    name: "Daily Ads",
    icon: <Icon component={MdVideoLibrary} />,
  },
  {
    path: webRoutes.buySubscription,
    key: webRoutes.buySubscription,
    name: "Buy Packages",
    icon: <Icon component={BiBasket} />,
  },
  {
    path: webRoutes.buyPackages,
    key: webRoutes.buyPackages,
    name: "Online Services",
    icon: <Icon component={BiBasket} />,
  },
  {
    path: webRoutes.blogging,
    key: webRoutes.blogging,
    name: "Blogging Earning Ads",
    icon: <Icon component={BiBasket} />,
  },
  {
    path: webRoutes.shopping,
    key: webRoutes.shopping,
    name: "Online Shopping",
    icon: <Icon component={BiCart} />,
  },
  {
    path: webRoutes.accounts,
    key: webRoutes.accounts,
    name: "Withdrawal Accounts",
    icon: <Icon component={BiWallet} />,
  },
  {
    path: webRoutes.balance,
    key: webRoutes.balance,
    name: "Balance",
    icon: <Icon component={BiMoney} />,
  },
  {
    path: webRoutes.withdrawRequest,
    key: webRoutes.withdrawRequest,
    name: "Withdrawal Request",
    icon: <Icon component={IoDocumentText} />,
  },
  {
    path: webRoutes.withdrawals,
    key: webRoutes.withdrawals,
    name: "Withdrawal History",
    icon: <Icon component={BiHistory} />,
  },
  {
    path: webRoutes.referralLinks,
    key: webRoutes.referralLinks,
    name: "Referral",
    icon: <Icon component={BiLinkExternal} />,
  },
  {
    path: webRoutes.notifications,
    key: webRoutes.notifications,
    name: "Notifications",
    icon: <Icon component={BiNotification} />,
  },
  {
    path: webRoutes.withdrawalsProofs,
    key: webRoutes.withdrawalsProofs,
    name: "Withdrawal Proofs",
    icon: <Icon component={BiMoneyWithdraw} />,
  },
  {
    path: webRoutes.profile,
    key: webRoutes.profile,
    name: "Profile",
    icon: <Icon component={BiUser} />,
  },
];
