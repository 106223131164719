import React from "react";
import { FaRegStar } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import plans from "../../../assets/Plans.png";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";

const Plans = () => {
  const cardVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: (i) => ({
      opacity: 1,
      scale: 1,
      transition: { delay: i * 0.2 },
    }),
  };

  return (
    <div>
      <Navbar />
      <div className="bg-black h-[40vh] text-white">
        <div className="mx-auto px-5 max-w-[670px] lg:max-w-[800px] xl:max-w-[1150px] 2xl:max-w-[1300px] h-[30vh]">
          <div className="flex justify-center flex-col md:flex-row gap-6 lg:gap-24 xl:gap-36 items-center h-[40vh] ">
            <h1 className="text-3xl lg:text-5xl font-bold">Investment Plans</h1>
            <motion.img
              src={plans}
              alt=""
              className="h-80 rounded-md"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          </div>
        </div>
      </div>
      <div className="mx-auto mt-10 px-5 max-w-[670px] lg:max-w-[800px] xl:max-w-[1150px] 2xl:max-w-[1300px] h-[30vh]">
        <h1 className="text-center font-bold md:text-2xl">
          Here's an explanation of the investment plans with packages ranging
          from $100 to $500
        </h1>
        <h1 className="mt-10 text-center lg:text-3xl font-bold ">
          Investment Plan Introduction
        </h1>
        <div className="flex items-center justify-center w-full flex-wrap gap-4 mt-5">
          {/* Basic */}
          <motion.div
            className="border flex flex-col w-full md:w-[350px] rounded-md items-center justify-center px-5 py-8"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            custom={0}
          >
            <div>
              <div className="flex items-center text-3xl">
                <FaRegStar /> <FaRegStar /> <FaRegStar />
              </div>
              <h1 className="font-extrabold mt-2">Basic ($100)</h1>
            </div>
            <div className="mt-4">
              <p>
                <span className="font-bold">Investment horizon:</span> 6 months
              </p>
              <p>
                <span className="font-bold">Asset allocation:</span> 60% stocks,
                40% bonds
              </p>
              <p>
                <span className="font-bold">Diversification:</span> 5 asset
                classes
              </p>
              <p>
                <span className="font-bold">Expected return:</span> 4-6% per
                annum
              </p>
              <p>
                <span className="font-bold">Management fee:</span> 1.5%
              </p>
            </div>
          </motion.div>

          {/* Premium */}
          <motion.div
            className="border flex flex-col w-full md:w-[350px] items-center justify-center px-5 py-8"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            custom={1}
          >
            <div>
              <div className="flex items-center text-3xl">
                <FaStar /> <FaRegStar className="" /> <FaRegStar />
              </div>
              <h1 className="font-extrabold mt-2">Premium ($200)</h1>
            </div>
            <div className="mt-4">
              <p>
                <span className="font-bold">Investment horizon:</span> 1 year
              </p>
              <p>
                <span className="font-bold">Asset allocation:</span> 70% stocks,
                30% bonds
              </p>
              <p>
                <span className="font-bold">Diversification:</span> 10 asset
                classes
              </p>
              <p>
                <span className="font-bold">Expected return:</span> 6-8% per
                annum
              </p>
              <p>
                <span className="font-bold">Management fee:</span> 1.2%
              </p>
            </div>
          </motion.div>

          {/* Pro */}
          <motion.div
            className="border flex flex-col w-full md:w-[350px] items-center justify-center px-5 py-8"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            custom={2}
          >
            <div>
              <div className="flex items-center text-3xl">
                <FaStar /> <FaStar className="" /> <FaRegStar />
              </div>
              <h1 className="font-extrabold mt-2">Pro ($250)</h1>
            </div>
            <div className="mt-4">
              <p>
                <span className="font-bold">Investment horizon:</span> 2 years
              </p>
              <p>
                <span className="font-bold">Asset allocation:</span> 80% stocks,
                20% bonds
              </p>
              <p>
                <span className="font-bold">Diversification:</span> 15 asset
                classes
              </p>
              <p>
                <span className="font-bold">Expected return:</span> 8-10% per
                annum
              </p>
              <p>
                <span className="font-bold">Management fee:</span> 1.0%
              </p>
            </div>
          </motion.div>

          {/* Elite */}
          <motion.div
            className="border flex w-full md:w-[350px] flex-col items-center justify-center px-10 py-8"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            custom={3}
          >
            <div>
              <div className="flex items-center text-3xl">
                <FaStar /> <FaStar className="" /> <FaStar />
              </div>
              <h1 className="font-extrabold mt-2">Elite ($500)</h1>
            </div>
            <div className="mt-4">
              <p>
                <span className="font-bold">Investment horizon:</span> 5 years
              </p>
              <p>
                <span className="font-bold">Asset allocation:</span> customized
              </p>
              <p>
                <span className="font-bold">Diversification:</span> 20+ asset
                classes
              </p>
              <p>
                <span className="font-bold">Expected return:</span> 10-12% per
                annum
              </p>
              <p>
                <span className="font-bold">Management fee:</span> 0.8%
              </p>
            </div>
          </motion.div>
        </div>

        <div className="flex justify-center items-center flex-col md:flex-row md:justify-around mt-10">
          <div className="px-20 space-y-2 ">
            <h1 className="text-5xl mt-7">Advantages</h1>
            <p className="mt-5">1. Diversified portfolios</p>
            <p>2. Potential for long-term growth</p>
            <p>3. Professional management</p>
            <p>4. Regular updates and reporting</p>
            <p>5. Flexibility to adjust investment horizon</p>
          </div>
          <div className="px-20 space-y-2 ">
            <h1 className="text-5xl mt-7">Disadvantages</h1>
            <p className="mt-5">1. Market risks (loss of principal)</p>
            <p>2. Management fees</p>
            <p>3. Minimum investment requirements</p>
            <p>4. Lock-in periods (penalty for early withdrawal)</p>
            <p>5. Tax implications (capital gains tax)</p>
          </div>
        </div>

        <div className="mt-20 space-y-2 flex flex-col justify-center items-center md:items-start ">
          <h1 className="text-4xl font-bold underline">Additional Features</h1>
          <p>1. Auto-investment option</p>
          <p>2. Quarterly rebalancing</p>
          <p>3. Tax-loss harvesting</p>
          <p>4. Dedicated customer support</p>
        </div>

        <div className="mt-16 py-5 px-5 space-y-2 flex flex-col justify-center items-center md:items-start">
          <h1 className=" text-4xl font-bold underline">Risk Disclosure</h1>
          <p>
            Investing carries inherent risks. Returns are not guaranteed, and
            principal amounts may fluctuate. It's essential to assess your risk
            tolerance and financial goals before investing.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Plans;
