import {
  GET_ADS_FAIL,
  GET_ADS_REQUEST,
  GET_ADS_SUCCESS,
  SUBMIT_AD_FAIL,
  SUBMIT_AD_REQUEST,
  SUBMIT_AD_SUCCESS,
} from "../constants/adConstants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  msg: "",
  ads: [],
};

export const GetAdsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_ADS_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_ADS_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      ads: payload,
      errors: [],
    };
  } else if (type === GET_ADS_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const SubmitAdReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === SUBMIT_AD_REQUEST) {
    return { ...state, loading: true };
  } else if (type === SUBMIT_AD_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === SUBMIT_AD_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};
