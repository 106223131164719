//User Signup
export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";

//User Login
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

//User Statistics
export const GET_STATISTICS_REQUEST = "GET_STATISTICS_REQUEST";
export const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS";
export const GET_STATISTICS_FAIL = "GET_STATISTICS_FAIL";

//Forgot Password
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL"

//Veify OTP
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST"
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS"
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL"

//Reset Password
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"

