import {
  CHANGE_NOTIFICATION_STATUS_FAIL,
  CHANGE_NOTIFICATION_STATUS_REQUEST,
  CHANGE_NOTIFICATION_STATUS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
} from "../constants/notificationConstants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  notifications: [],
};

export const GetNotificationsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_NOTIFICATIONS_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_NOTIFICATIONS_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      notifications: payload,
      errors: [],
    };
  } else if (type === GET_NOTIFICATIONS_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const ChangeNotificationStatusReducer = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  if (type === CHANGE_NOTIFICATION_STATUS_REQUEST) {
    return { ...state, loading: true };
  } else if (type === CHANGE_NOTIFICATION_STATUS_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === CHANGE_NOTIFICATION_STATUS_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};
