export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

export const CHANGE_NOTIFICATION_STATUS_REQUEST =
  "CHANGE_NOTIFICATION_STATUS_REQUEST";
export const CHANGE_NOTIFICATION_STATUS_SUCCESS =
  "CHANGE_NOTIFICATION_STATUS_SUCCESS";
export const CHANGE_NOTIFICATION_STATUS_FAIL =
  "CHANGE_NOTIFICATION_STATUS_FAIL";
