import {
  CREATE_WITHDRAW_FAIL,
  CREATE_WITHDRAW_REQUEST,
  CREATE_WITHDRAW_SUCCESS,
  GET_WITHDRAW_HISTORY_FAIL,
  GET_WITHDRAW_HISTORY_REQUEST,
  GET_WITHDRAW_HISTORY_SUCCESS,
  GET_WITHDRAW_PROOF_FAIL,
  GET_WITHDRAW_PROOF_REQUEST,
  GET_WITHDRAW_PROOF_SUCCESS,
} from "../constants/withdrawContants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  msg: "",
  withdraws: [],
  proofs: [],
};

export const CreateWithdrawReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === CREATE_WITHDRAW_REQUEST) {
    return { ...state, loading: true };
  } else if (type === CREATE_WITHDRAW_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      msg: payload,
      errors: [],
    };
  } else if (type === CREATE_WITHDRAW_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const GetWithdrawHistoryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_WITHDRAW_HISTORY_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_WITHDRAW_HISTORY_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      withdraws: payload,
      errors: [],
    };
  } else if (type === GET_WITHDRAW_HISTORY_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const GetWithdrawProofReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_WITHDRAW_PROOF_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_WITHDRAW_PROOF_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      proofs: payload,
      errors: [],
    };
  } else if (type === GET_WITHDRAW_PROOF_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};
