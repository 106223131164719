import React from "react";
import { motion } from "framer-motion";
import services from "../../../assets/services.png";
import courses from "../../../assets/onlinecourses.png";
import tutorial from "../../../assets/tutorials.png";
import webinars from "../../../assets/webinars.png";
import certification from "../../../assets/certification.png";
import resources from "../../../assets/resources.png";
import payment from "../../../assets/paymentSystem .webp";
import meeting from "../../../assets/virtualmeeting.png";
import marketPlace from "../../../assets/onlinemarketplace.png";
import shopping from "../../../assets/ecomshopping.png";
import adsense from "../../../assets/youtubeService/adsense.png";
import sponsorship from "../../../assets/youtubeService/sponsorships.png";
import merchandise from "../../../assets/youtubeService/merchandise.png";
import premiumcontent from "../../../assets/youtubeService/premiumcontent.png";
import youtubepremium from "../../../assets/youtubeService/youtubePremium.png";
import content from "../../../assets/tiktok service/content.png";
import marketing from "../../../assets/tiktok service/marketing.png";
import Hashtag from "../../../assets/tiktok service/hashtag.png";
import ads from "../../../assets/tiktok service/tiktokads.svg";
import tiktokshopping from "../../../assets/tiktok service/tiktokshopping.png";
import websiteDevelopment from "../../../assets/otherServices/websitedevelopment.png";
import seo from "../../../assets/otherServices/seoOptimization .avif";
import socialMedia from "../../../assets/otherServices/socailMediamanagment.avif";
import online from "../../../assets/otherServices/onlineSupport.png";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import Navbar from "../components/Navbar";
// import { AiFillTikTok } from "react-icons/ai";

const Services = () => {
  // Animation variants for cards
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, delay: i * 0.2, ease: "easeOut" },
    }),
  };

  return (
    <div>
      <Navbar />
      <div className="mx-auto max-w-[1300px] h-[30vh]">
        <div className="flex  justify-center items-center gap-12 mt-16 flex-col md:flex-row">
          <img src={services} alt="Our Services" className="h-96 rounded-md" />
          <h1 className="text-5xl font-bold">
            Our <span className="text-primary">Services</span>
          </h1>
        </div>
        <h1 className="text-center font-bold text-4xl mt-20">
          E-Learning Platforms
        </h1>

        <div className="flex px-6 flex-col justify-center items-center gap-8 mt-10">
          <div className="flex justify-center flex-wrap lg:flex-nowrap items-center gap-6 flex-col md:flex-row w-full">
            {/* Card 1 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px] xl:w-[350px] 2xl:[400px] flex flex-col items-center justify-center py-10 px-6 bg-white  rounded-lg "
            >
              <img src={courses} alt="Online Courses" className="h-20" />
              <h1 className="font-bold mt-2">Online Courses</h1>
              <p className="text-gray-600 font-bold">
                Structured lessons on various subjects (e.g., finance,
                marketing)
              </p>
            </motion.div>

            {/* Card 2 */}
            <motion.div
              variants={cardVariants}
              custom={1}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border  w-full lg:w-[300px] xl:w-[350px] 2xl:[400px] flex flex-col items-center justify-center py-10 px-6 bg-white  rounded-lg "
            >
              <img src={tutorial} alt="Tutorials" className="h-20" />
              <h1 className="font-bold mt-2">Tutorials</h1>
              <p className="text-gray-600 font-bold">
                Step-by-step guides for skill development (e.g., programming)
              </p>
            </motion.div>

            {/* Card 3 */}
            <motion.div
              variants={cardVariants}
              custom={2}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border  w-full lg:w-[300px] xl:w-[350px] 2xl:[400px] flex flex-col items-center justify-center py-10 px-6 bg-white  rounded-lg "
            >
              <img src={webinars} alt="Webinars" className="h-20" />
              <h1 className="font-bold mt-2">Webinars</h1>
              <p className="text-gray-600 font-bold">
                Live or recorded video conferences for education
              </p>
            </motion.div>
          </div>

          <div className="flex  px-6 items-center flex-wrap lg:flex-nowrap justify-center gap-6 flex-col md:flex-row w-full">
            {/* Card 4 */}
            <motion.div
              variants={cardVariants}
              custom={3}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border  w-full lg:w-[300px] xl:w-[350px] 2xl:[400px] flex flex-col items-center justify-center py-10 px-6 bg-white rounded-lg"
            >
              <img src={certification} alt="Certifications" className="h-20" />
              <h1 className="font-bold mt-2">Certifications</h1>
              <p className="text-gray-600 font-bold">
                Recognized credentials upon course completion
              </p>
            </motion.div>

            {/* Card 5 */}
            <motion.div
              variants={cardVariants}
              custom={4}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px] xl:w-[350px] 2xl:[400px] flex flex-col items-center justify-center py-10 px-6 bg-white  rounded-lg "
            >
              <img src={resources} alt="Resources" className="h-20" />
              <h1 className="font-bold mt-2">Resources</h1>
              <p className="text-gray-600 font-bold">
                eBooks, articles, podcasts, and <br /> videos
              </p>
            </motion.div>
          </div>
        </div>

        <h1 className="text-center  font-bold text-4xl mt-20">
          Social Media Platforms
        </h1>

        <div className="flex  px-6 flex-wrap lg:flex-nowrap flex-col justify-center items-center gap-8 mt-10">
          <div className="flex justify-center items-center gap-6 flex-col md:flex-row w-full">
            {/* Card 1 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px] xl:w-[350px] h-72 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <FaSquareFacebook className="text-5xl text-blue-400" />
              <h1 className="font-bold mt-2 text-2xl">Facebook</h1>
              <p className="text-gray-600 font-bold mt-5">Profile creation</p>
              <p className="text-gray-600 font-bold">Networking</p>
              <p className="text-gray-600 font-bold">
                Content sharing (text, images, videos)
              </p>
              <p className="text-gray-600 font-bold">
                Advertising (targeted ads)
              </p>
              <p className="text-gray-600 font-bold">Groups and communities</p>
            </motion.div>

            {/* Card 2 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <FaSquareTwitter className="text-5xl text-blue-400" />
              <h1 className="font-bold mt-2 text-2xl">Twitter</h1>
              <p className="text-gray-600 font-bold mt-5">
                Real-time updates (tweets)
              </p>
              <p className="text-gray-600 font-bold">
                Hashtag-based discussions
              </p>
              <p className="text-gray-600 font-bold">Networking</p>
              <p className="text-gray-600 font-bold">
                Advertising (promoted tweets)
              </p>
            </motion.div>

            {/* Card 3 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <FaYoutube className="text-5xl" />
              <h1 className="font-bold mt-2 text-2xl">YouTube</h1>
              <p className="text-gray-600 font-bold mt-5">Video sharing</p>
              <p className="text-gray-600 font-bold">
                Monetization (ads, sponsorships)
              </p>
              <p className="text-gray-600 font-bold">
                Subscriptions (channels)
              </p>
              <p className="text-gray-600 font-bold">
                Views and engagement tracking
              </p>
              <p className="text-gray-600 font-bold">
                CPM (cost-per-thousand impressions) advertising
              </p>
            </motion.div>
          </div>

          <div className="flex items-center justify-center gap-6 flex-col md:flex-row w-full">
            {/* Card 4 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              {/* <AiFillTikTok className="text-5xl text-black" /> */}
              <h1 className="font-bold mt-2 text-2xl">TikTok</h1>
              <p className="text-gray-600 font-bold mt-5">
                Short-form video sharing
              </p>
              <p className="text-gray-600 font-bold">Community engagement</p>
              <p className="text-gray-600 font-bold">Hashtag challenges</p>
              <p className="text-gray-600 font-bold">
                Advertising (branded content)
              </p>
              <p className="text-gray-600 font-bold">Influencer partnerships</p>
            </motion.div>
          </div>
        </div>
        {/* Additional services  */}
        <h1 className="text-center font-bold text-4xl mt-20">
          Additional Services
        </h1>

        <div className="flex  px-6  flex-wrap lg:flex-nowrap flex-col justify-center items-center gap-8 mt-10">
          <div className="flex flex-wrap lg:flex-nowrap justify-center items-center gap-6 flex-col md:flex-row w-full">
            {/* Card 1 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px] xl:w-[350px] h-72 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={shopping} alt="" className="h-20" />

              <h1 className="font-bold mt-2 ">Online Shopping (E-commerce)</h1>
              <p className="text-gray-600 font-bold mt-5">Product sales</p>
              <p className="text-gray-600 font-bold">Digital payments</p>
              <p className="text-gray-600 font-bold">Order tracking</p>
              <p className="text-gray-600 font-bold">Customer support</p>
            </motion.div>

            {/* Card 2 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={payment} alt="" className="h-20" />
              <h1 className="font-bold mt-2 "> Digital Payment Systems</h1>
              <p className="text-gray-600 font-bold mt-5">
                Online transactions
              </p>
              <p className="text-gray-600 font-bold">Wallet services</p>
              <p className="text-gray-600 font-bold">Payment processing</p>
              <p className="text-gray-600 font-bold">Security measures</p>
            </motion.div>

            {/* Card 3 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={meeting} alt="" className="h-20" />
              <h1 className="font-bold mt-2 ">
                Virtual Meeting and Conferencing
              </h1>
              <p className="text-gray-600 font-bold mt-5">Video conferencing</p>
              <p className="text-gray-600 font-bold">Screen sharing</p>
              <p className="text-gray-600 font-bold">Audio calls</p>
              <p className="text-gray-600 font-bold">Chat and messaging</p>
            </motion.div>
          </div>

          <div className="flex items-center justify-center gap-6 flex-col md:flex-row w-full">
            {/* Card 4 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={marketPlace} alt="" className="h-16" />
              <h1 className="font-bold mt-2"> Online Marketplaces</h1>
              <p className="text-gray-600 font-bold mt-5">
                Freelance work platforms
              </p>
              <p className="text-gray-600 font-bold">Job boards</p>
              <p className="text-gray-600 font-bold">Service listings</p>
              <p className="text-gray-600 font-bold">Review systems</p>
            </motion.div>
          </div>
        </div>
        {/* YouTube Monetization Services */}
        <h1 className="text-center font-bold text-4xl mt-20">
          YouTube Monetization Services
        </h1>

        <div className="flex px-6 flex-wrap lg:flex-nowrap flex-col justify-center items-center gap-8 mt-10">
          <div className="flex justify-center flex-wrap lg:flex-nowrap items-center gap-6 flex-col md:flex-row w-full">
            {/* Card 1 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px] xl:w-[350px] h-72 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={adsense} alt="" className="h-20" />

              <h1 className="font-bold mt-2 ">AdSense</h1>
              <p className="text-gray-600 font-bold mt-5">
                Display ads on videos
              </p>
            </motion.div>

            {/* Card 2 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={sponsorship} alt="" className="h-20" />
              <h1 className="font-bold mt-2 ">Sponsorships</h1>
              <p className="text-gray-600 font-bold mt-5">
                Partner with brands for content promotion
              </p>
            </motion.div>

            {/* Card 3 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={merchandise} alt="" className="h-20" />
              <h1 className="font-bold mt-2 ">Merchandise</h1>
              <p className="text-gray-600 font-bold mt-5">
                Sell products or merchandise through videos
              </p>
            </motion.div>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap items-center justify-center gap-6 flex-col md:flex-row w-full">
            {/* Card 4 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={premiumcontent} alt="" className="h-16" />
              <h1 className="font-bold mt-2">Premium content</h1>
              <p className="text-gray-600 font-bold mt-5">
                Offer exclusive content for subscription-based models
              </p>
            </motion.div>

            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={youtubepremium} alt="" className="h-16" />
              <h1 className="font-bold mt-2">YouTube Premium</h1>
              <p className="text-gray-600 font-bold mt-5">
                Earn revenue from YouTube Premium subscribers
              </p>
            </motion.div>
          </div>
        </div>

        {/* TikTok Services */}
        <h1 className="text-center font-bold text-4xl mt-20">
          TikTok Services
        </h1>

        <div className="flex flex-wrap lg:flex-nowrap  px-6 flex-col justify-center items-center gap-8 mt-10">
          <div className="flex flex-wrap lg:flex-nowrap justify-center items-center gap-6 flex-col md:flex-row w-full">
            {/* Card 1 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px] xl:w-[350px] h-72 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={content} alt="" className="h-20" />

              <h1 className="font-bold mt-2 ">Branded Content</h1>
              <p className="text-gray-600 font-bold mt-5">
                Partner with brands for sponsored content
              </p>
            </motion.div>

            {/* Card 2 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={marketing} alt="" className="h-20" />
              <h1 className="font-bold mt-2 ">Influencer Marketing</h1>
              <p className="text-gray-600 font-bold mt-5">
                Collaborate with popular creators
              </p>
            </motion.div>

            {/* Card 3 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={Hashtag} alt="" className="h-20" />
              <h1 className="font-bold mt-2 ">Hashtag Challenges</h1>
              <p className="text-gray-600 font-bold mt-5">
                Create and participate in viral challenges
              </p>
            </motion.div>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap items-center justify-center gap-6 flex-col md:flex-row w-full">
            {/* Card 4 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={ads} alt="" className="h-16" />
              <h1 className="font-bold mt-2">TikTok Ads</h1>
              <p className="text-gray-600 font-bold mt-5">
                Run targeted advertisements
              </p>
            </motion.div>

            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={tiktokshopping} alt="" className="h-16" />
              <h1 className="font-bold mt-2">TikTok Shopping</h1>
              <p className="text-gray-600 font-bold mt-5">
                Tag products in videos for seamless shopping
              </p>
            </motion.div>
          </div>
        </div>

        {/* Other Services */}
        <h1 className="text-center font-bold text-4xl mt-20">
          Other Services
        </h1>

        <div className="flex flex-wrap lg:flex-nowrap  px-6 flex-col justify-center items-center gap-8 mt-10">
          <div className="flex flex-wrap lg:flex-nowrap justify-center items-center gap-6 flex-col md:flex-row w-full">
            {/* Card 1 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px] xl:w-[350px] h-72 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={websiteDevelopment} alt="" className="h-20" />

              <h1 className="font-bold mt-2 ">Website Development</h1>
              <p className="text-gray-600 font-bold mt-5">
                Create custom websites
              </p>
            </motion.div>

            {/* Card 2 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={content} alt="" className="h-20" />
              <h1 className="font-bold mt-2 "> Content Creation</h1>
              <p className="text-gray-600 font-bold mt-5">
                Produce high-quality content (text, images, videos)
              </p>
            </motion.div>

            {/* Card 3 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={seo} alt="" className="h-20" />
              <h1 className="font-bold mt-2 ">SEO Optimization</h1>
              <p className="text-gray-600 font-bold mt-5">
                Improve search engine rankings
              </p>
            </motion.div>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap items-center justify-center gap-6 flex-col md:flex-row w-full">
            {/* Card 4 */}
            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={socialMedia} alt="" className="h-16" />
              <h1 className="font-bold mt-2"> Social Media Management</h1>
              <p className="text-gray-600 font-bold mt-5">
                Manage social media presence
              </p>
            </motion.div>

            <motion.div
              variants={cardVariants}
              custom={0}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="border w-full lg:w-[300px]  h-72 xl:w-[350px] 2xl:[450px] flex flex-col items-center justify-center py-6 px-6 bg-white  rounded-lg "
            >
              <img src={online} alt="" className="h-16" />
              <h1 className="font-bold mt-2">Online Support</h1>
              <p className="text-gray-600 font-bold mt-5">
                Provide customer support through various channels
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
