import React, { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [openNav, setOpenNav] = useState(false);

  const handleNav = () => {
    setOpenNav(!openNav);
  };

  return (
    <header className="bg-white shadow-md">
      <div className=" mx-auto px-4 flex justify-between md:justify-center lg:gap-32 xl:gap-80 2xl:gap-96 items-center py-2">
        <div className="text-2xl font-bold">
          <Link to="/">
            <span className="font-poppins font-semibold">
              Earning <span className="text-primary">&</span> Learning
            </span>
          </Link>
        </div>
        <div className="md:hidden">
          <Link
            to="/login"
            className="text-gray-700 font-bold hover:text-gray-900  mr-12"
          >
            Login
          </Link>
          <RxHamburgerMenu
            className="text-4xl cursor-pointer absolute right-2 top-4 border border-gray-400 p-2"
            onClick={handleNav}
          />
        </div>

        {/* Navbar Links */}
        <nav
          className={`overflow-hidden transition-all duration-500 ease-in-out ${
            openNav ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
          } md:opacity-100 md:max-h-none md:flex md:items-center absolute md:static top-14 left-0 right-0 bg-white md:bg-transparent z-50 w-full md:w-auto`}
        >
          <ul className="flex ml-2 flex-col md:flex-row md:space-x-6 space-y-4 md:space-y-0 p-4 md:p-0">
            <li>
              <Link
                to="/"
                className="text-gray-700 font-bold hover:text-gray-900"
                onClick={handleNav}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/adsEarning"
                className="text-gray-700 font-bold hover:text-gray-900"
                onClick={handleNav}
              >
                Ads Earning
              </Link>
            </li>
            <li>
              <Link
                to="/Services"
                className="text-gray-700 font-bold hover:text-gray-900"
                onClick={handleNav}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/online-shopping"
                className="text-gray-700 font-bold hover:text-gray-900"
                onClick={handleNav}
              >
                Online Shopping
              </Link>
            </li>
            <li>
              <Link
                to="/investmentPlans"
                className="text-gray-700 font-bold hover:text-gray-900"
                onClick={handleNav}
              >
                Plans
              </Link>
            </li>
            <li>
              <Link
                to="/aboutus"
                className="text-gray-700 font-bold hover:text-gray-900"
                onClick={handleNav}
              >
                About
              </Link>
            </li>

            {/* <li>
              <Link
                to="/contactus"
                className="text-gray-700 font-bold hover:text-gray-900"
                onClick={handleNav}
              >
                Contact
              </Link>
            </li> */}
            <li>
              <Link
                to="/login"
                className="text-gray-700 font-bold hover:text-gray-900"
                onClick={handleNav}
              >
                Login
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
