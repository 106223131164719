import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

//Components
import { webRoutes } from "./web";

export default function RequireAuth({ children }) {
  const location = useLocation();
  const { user } = useSelector((state) => state.UserLoginReducer);

  if (!user) {
    return <Navigate to={webRoutes.login} state={{ from: location }} replace />;
  }
  return children;
}
