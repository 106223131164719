export const webRoutes = {
  home: "/",
  adEarning: "/adsEarning",
  services: "/Services",
  OnlineShopping: "/online-shopping",
  InvestmentPlans: "/investmentPlans",
  about: "/aboutus",
  login: "/login",
  signup: "/signup",
  forgotPassword: "/forgot-password",
  verifyOTP: "/verify-otp/:email",
  resetPassword: "/reset-password/:email",
  dashboard: "/dashboard",
  membership: "/membership",
  dailyAds: "/daily-ads",
  buySubscription: "/buy-subscription",
  buyPackages: "/buy-packages",
  newMembership: "/new-membership/:id",
  accounts: "/accounts",
  balance: "/balance",
  withdrawRequest: "/withdraw-request",
  withdrawals: "/withdrawals",
  referralLinks: "/referral-link",
  notifications: "/notifications",
  withdrawalsProofs: "/withdrawals-proofs",
  profile: "/profile",
  blogging: "/blogging",
  shopping: "/shopping",
};
