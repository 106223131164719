import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import about1 from "../../../assets/home.png";
import about2 from "../../../assets/services.png";
import about3 from "../../../assets/onlineeShopping.jpeg";
import Plans from "../../../assets/Plans.png";
import Navbar from "../components/Navbar";

const AboutUs = () => {
  // Controls for animation
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();

  // Setting up intersection observers for each card
  const { ref: card1Ref, inView: inViewCard1 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const { ref: card2Ref, inView: inViewCard2 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const { ref: card3Ref, inView: inViewCard3 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const { ref: card4Ref, inView: inViewCard4 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // Trigger animations based on inView state
  React.useEffect(() => {
    if (inViewCard1) controls1.start({ opacity: 1, x: 0 });
    else controls1.start({ opacity: 0, x: -100 });
  }, [inViewCard1, controls1]);

  React.useEffect(() => {
    if (inViewCard2) controls2.start({ opacity: 1, x: 0 });
    else controls2.start({ opacity: 0, x: 100 });
  }, [inViewCard2, controls2]);

  React.useEffect(() => {
    if (inViewCard3) controls3.start({ opacity: 1, x: 0 });
    else controls3.start({ opacity: 0, x: -100 });
  }, [inViewCard3, controls3]);

  React.useEffect(() => {
    if (inViewCard4) controls4.start({ opacity: 1, x: 0 });
    else controls4.start({ opacity: 0, x: 100 });
  }, [inViewCard4, controls4]);

  return (
    <div>
      <Navbar />
      <div className="about">
        <div className="mx-auto max-w-[670px] lg:max-w-[800px] xl:max-w-[1150px] 2xl:max-w-[1300px] h-[30vh]">
          <div className="flex justify-start items-center h-[30vh]">
            <h1 className="text-4xl font-extrabold">About Us</h1>
          </div>

          {/* First card with animation */}
          <motion.div
            ref={card1Ref}
            animate={controls1}
            initial={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.8 }}
            className="flex items-center flex-col md:flex-row bg-[#ffffff] gap-20 shadow-lg -mt-12 rounded-md p-2 px-8"
          >
            <p className="text-black font-semibold">
              Earning and Learning, we believe in empowering individuals with
              the knowledge and resources needed to succeed. Our team consists
              of experts in finance, marketing, and education.
            </p>
            <img src={about1} alt="" className="lg:h-80 h-72" />
          </motion.div>

          {/* Second card with animation */}
          <motion.div
            ref={card2Ref}
            animate={controls2}
            initial={{ opacity: 0, x: 100 }}
            transition={{ duration: 0.8 }}
            className="flex items-center flex-col md:flex-row bg-[#ffffff] shadow-lg gap-10 rounded-md p-2 px-8 mt-20"
          >
            <img src={about2} alt="" className="lg:h-80 h-72" />
            <div>
              <h1 className="text-black text-2xl font-black">Our Services</h1>
              <p className="text-black">
                <span className="font-bold">1. Online Courses:</span> Expert-led
                tutorials on finance, marketing, and entrepreneurship
              </p>
              <p className="text-black">
                <span className="font-bold">2. Advertising Platform:</span>{" "}
                Watch ads, earn money, and monetize your online presence
              </p>
              <p className="text-black">
                <span className="font-bold">3. Online Shopping:</span> Partnered
                with trusted merchants for secure and convenient shopping
              </p>
              <p className="text-black">
                <span className="font-bold">4. Investment Plans:</span> Guided
                investment strategies and portfolio management
              </p>
            </div>
          </motion.div>

          {/* Third card with animation */}
          <motion.div
            ref={card3Ref}
            animate={controls3}
            initial={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.8 }}
            className="flex items-center justify-between mt-5 flex-col md:flex-row bg-[#ffffff] gap-20 shadow-lg rounded-md p-2 px-8"
          >
            <div>
              <h1 className="text-black text-2xl font-black">
                Getting started
              </h1>
              <p className="text-black">
                Create Account & Sign Up <br />
                1. Go to our website: [website URL] <br />
                2. Click "Sign Up" and fill out the registration form <br />
                3. Verify your email address <br />
                4. Complete your profile
              </p>
              <h1 className="text-black text-2xl font-black mt-2">
                Free Earning with Watching Ads
              </h1>
              <p className="text-black mt-2">
                1. Log in to your account <br />
                2. Navigate to the "Watch & Earn" section <br />
                3. Choose from available ads <br />
                4. Watch ads to earn rewards
              </p>
            </div>
            <img src={about3} alt="" className="lg:h-80 h-72" />
          </motion.div>

          {/* Fourth card with animation */}
          <motion.div
            ref={card4Ref}
            animate={controls4}
            initial={{ opacity: 0, x: 100 }}
            transition={{ duration: 0.8 }}
            className="flex items-center justify-between mt-8 flex-col md:flex-row bg-[#ffffff] gap-20 shadow-lg rounded-md p-2 px-8"
          >
            <img src={Plans} alt="" className="lg:h-80 h-72" />
            <div>
              <h1 className="text-black text-2xl font-black">
                Investment Options
              </h1>
              <p className="text-black">
                1. Log in to your account
                <br />
                2. Navigate to the "Invest" section <br />
                3. Choose from available investment plans
                <br />
                4. Follow guided investment strategies
              </p>
              <h1 className="text-black text-2xl font-black mt-2">
                Online Shopping
              </h1>
              <p className="text-black mt-2">
                1. Log in to your account
                <br />
                2. Navigate to the "Packages" section <br />
                3. Choose from available packages (e.g., premium courses,
                ad-free experience) <br />
                4. Subscribe to your chosen package
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
