import { jwtDecode } from "jwt-decode";

import {
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  GET_STATISTICS_FAIL,
  GET_STATISTICS_REQUEST,
  GET_STATISTICS_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  VERIFY_OTP_FAIL,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
} from "../constants/authConstants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  statistics: [],
  token: "",
  user: "",
  msg: "",
};

const decodeToken = (token) => {
  const decodedToken = jwtDecode(token);
  return decodedToken;
};

const token = localStorage.getItem("USER_TOKEN");
if (token) {
  const decoded = decodeToken(token);
  if (decoded) {
    initialState.token = token;
    const { id } = decoded;
    initialState.user = id;
  }
}

export const UserSignupReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === USER_SIGNUP_REQUEST) {
    return { ...state, loading: true };
  } else if (type === USER_SIGNUP_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      msg: payload.msg,
      errors: [],
    };
  } else if (type === USER_SIGNUP_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const UserLoginReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === USER_LOGIN_REQUEST) {
    return { ...state, loading: true };
  } else if (type === USER_LOGIN_SUCCESS) {
    const decoded = decodeToken(payload.token);
    const { id } = decoded;
    return {
      ...state,
      loading: false,
      success: true,
      token: payload.token,
      user: id,
      msg: payload.msg,
      errors: [],
    };
  } else if (type === USER_LOGIN_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else if (type === USER_LOGOUT) {
    localStorage.removeItem("USER_TOKEN");
    return { ...state, token: "", user: "" };
  } else {
    return state;
  }
};

export const UserStatisticsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_STATISTICS_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_STATISTICS_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      statistics: payload,
      errors: [],
    };
  } else if (type === GET_STATISTICS_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const ForgotPasswordReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === FORGOT_PASSWORD_REQUEST) {
    return { ...state, loading: true };
  } else if (type === FORGOT_PASSWORD_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === FORGOT_PASSWORD_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const VerifyOTPReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === VERIFY_OTP_REQUEST) {
    return { ...state, loading: true };
  } else if (type === VERIFY_OTP_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === VERIFY_OTP_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const ResetPasswordReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === RESET_PASSWORD_REQUEST) {
    return { ...state, loading: true };
  } else if (type === RESET_PASSWORD_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === RESET_PASSWORD_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};