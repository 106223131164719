import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import about1 from "../../../assets/adsBanner.png";
import about2 from "../../../assets/adBanner2.png";
import about3 from "../../../assets/adBanner3.png";
import Navbar from "../components/Navbar";

const Earning = () => {
  // Controls for animation
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  // Setting up intersection observers for each card
  const { ref: card1Ref, inView: inViewCard1 } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  const { ref: card2Ref, inView: inViewCard2 } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  const { ref: card3Ref, inView: inViewCard3 } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  // Triggering animations based on inView state
  React.useEffect(() => {
    if (inViewCard1) {
      controls1.start({ opacity: 1, x: 0 });
    } else {
      controls1.start({ opacity: 0, x: -100 });
    }
  }, [inViewCard1, controls1]);

  React.useEffect(() => {
    if (inViewCard2) {
      controls2.start({ opacity: 1, x: 0 });
    } else {
      controls2.start({ opacity: 0, x: 100 });
    }
  }, [inViewCard2, controls2]);

  React.useEffect(() => {
    if (inViewCard3) {
      controls3.start({ opacity: 1, x: 0 });
    } else {
      controls3.start({ opacity: 0, x: -100 });
    }
  }, [inViewCard3, controls3]);

  return (
    <div className="about">
      <Navbar />

      <div className="mx-auto px-6 md:px-0 max-w-[670px] lg:max-w-[800px] xl:max-w-[1150px] 2xl:max-w-[1300px] h-[30vh]">
        <div className="flex justify-start items-center h-[30vh]">
          <h1 className="lg:text-5xl text-2xl md:text-4xl xl:text-7xl font-extrabold">
            Ads Earning
          </h1>
        </div>

        {/* First card with animation */}
        <motion.div
          ref={card1Ref}
          animate={controls1}
          initial={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.8 }}
          className="flex items-center justify-between flex-col md:flex-row bg-[#ffffff] gap-20 shadow-lg -mt-12 rounded-md p-7 px-8"
        >
          <div>
            <p className="text-black font-bold text-[18px]">
              Here's an explanation of YouTube video ads watching earning:
            </p>
            <h1 className=" font-bold">
              What is YouTube Ads Watching Earning?
            </h1>
            <h1 className="text-black mt-2">
              YouTube Ads Watching Earning is a program that allows users to
              earn money by watching video ads on the YouTube platform.
            </h1>
            <h1 className="font-bold mt-2">How Does it Work?</h1>
            <p className="text-black mt-2">
              1. Users sign up for a YouTube account and enable monetization.
            </p>
            <p className="text-black">
              2. Users watch video ads displayed before, during, or after
              YouTube videos.
            </p>
            <p className="text-black">
              3. For each ad watched, users earn a portion of the ad revenue.
            </p>
            <p className="text-black">
              4. Earnings are credited to the user's YouTube account.
            </p>
          </div>
          <img src={about1} alt="" className="h-80" />
        </motion.div>

        {/* Second card with animation */}
        <motion.div
          ref={card2Ref}
          animate={controls2}
          initial={{ opacity: 0, x: 100 }}
          transition={{ duration: 0.8 }}
          className="flex items-center  flex-col md:flex-row bg-[#ffffff] shadow-lg gap-10 rounded-md p-4 px-8 mt-20"
        >
          <img src={about2} alt="" className="h-96" />
          <div>
            <h1 className="text-2xl font-bold ">Earning Potential</h1>
            <p className="text-black mt-2">
              1. Variable rates: $0.01-$0.001 per ad view
            </p>
            <p className="text-black">
              2. Daily/weekly/monthly earning limits: $1-$100
            </p>
            <p className="text-black">
              3. Bonus rewards: <br />
              - Milestones (e.g., 1000 ad views): $1-$10 bonus <br />- Task
              completion (e.g., watching 30 ads): $0.50-$5 bonus
            </p>
            <h1 className="text-2xl font-bold mt-3">Ad Requirements</h1>
            <p className="text-black mt-2">1. Minimum ad length: 30 seconds</p>
            <p className="text-black">
              2. Ad format: Video ads (skippable or non-skippable)
            </p>
            <p className="text-black">
              3. Ad display: Pre-roll, mid-roll, or post-roll
            </p>
          </div>
        </motion.div>

        {/* Third card with animation */}
        <motion.div
          ref={card3Ref}
          animate={controls3}
          initial={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.8 }}
          className="flex items-center  justify-between flex-col md:flex-row bg-[#ffffff] gap-20 shadow-lg mt-12 rounded-md p-2 px-8"
        >
          <div>
            <h1 className=" font-bold text-2xl md:text-3xl">Tips</h1>

            <p className="text-black mt-2">
              1. Watch ads regularly to increase earnings.
            </p>
            <p className="text-black">
              2. Create content to attract more viewers and ad revenue.
            </p>
            <p className="text-black">
              3. Utilize YouTube's analytics tools to optimize ad performance.
            </p>
            <p className="text-black">
              4. Comply with YouTube's policies to avoid account suspension.
            </p>
            <p className="text-black mt-2 font-bold">
              Keep in mind that YouTube's algorithm and ad pricing may change,
              affecting earning potentials
            </p>
          </div>
          <img src={about3} alt="" className="h-80" />
        </motion.div>
      </div>
    </div>
  );
};

export default Earning;
