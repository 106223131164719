import {
  GET_PACKAGES_FAIL,
  GET_PACKAGES_REQUEST,
  GET_PACKAGES_SUCCESS,
} from "../constants/packageConstants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  msg: "",
  packages: [],
};

export const GetPackagesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_PACKAGES_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_PACKAGES_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      packages: payload,
      errors: [],
    };
  } else if (type === GET_PACKAGES_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};
