import {
  CREATE_USER_PAYMENT_FAIL,
  CREATE_USER_PAYMENT_REQUEST,
  CREATE_USER_PAYMENT_SUCCESS,
  EDIT_USER_PAYMENT_FAIL,
  EDIT_USER_PAYMENT_REQUEST,
  EDIT_USER_PAYMENT_SUCCESS,
  GET_ADMIN_PAYMENT_FAIL,
  GET_ADMIN_PAYMENT_REQUEST,
  GET_ADMIN_PAYMENT_SUCCESS,
  GET_USER_PAYMENT_FAIL,
  GET_USER_PAYMENT_REQUEST,
  GET_USER_PAYMENT_SUCCESS,
} from "../constants/paymentConstants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  msg: "",
  adminPayments: [],
  userpayments: [],
};

export const GetAdminPaymentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_ADMIN_PAYMENT_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_ADMIN_PAYMENT_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      adminPayments: payload,
      errors: [],
    };
  } else if (type === GET_ADMIN_PAYMENT_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const CreateUserPaymentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === CREATE_USER_PAYMENT_REQUEST) {
    return { ...state, loading: true };
  } else if (type === CREATE_USER_PAYMENT_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      msg: payload.msg,
      errors: [],
    };
  } else if (type === CREATE_USER_PAYMENT_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const GetUserPaymentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_USER_PAYMENT_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_USER_PAYMENT_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      userpayments: payload,
      errors: [],
    };
  } else if (type === GET_USER_PAYMENT_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const EditUserPaymentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === EDIT_USER_PAYMENT_REQUEST) {
    return { ...state, loading: true };
  } else if (type === EDIT_USER_PAYMENT_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      msg: payload.msg,
      errors: [],
    };
  } else if (type === EDIT_USER_PAYMENT_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};
