//Get Admin Payment Method
export const GET_ADMIN_PAYMENT_REQUEST = "GET_ADMIN_PAYMENT_REQUEST";
export const GET_ADMIN_PAYMENT_SUCCESS = "GET_ADMIN_PAYMENT_SUCCESS";
export const GET_ADMIN_PAYMENT_FAIL = "GET_ADMIN_PAYMENT_FAIL";

//Create User Payment
export const CREATE_USER_PAYMENT_REQUEST = "CREATE_USER_PAYMENT_REQUEST";
export const CREATE_USER_PAYMENT_SUCCESS = "CREATE_USER_PAYMENT_SUCCESS";
export const CREATE_USER_PAYMENT_FAIL = "CREATE_USER_PAYMENT_FAIL";

export const GET_USER_PAYMENT_REQUEST = "GET_USER_PAYMENT_REQUEST";
export const GET_USER_PAYMENT_SUCCESS = "GET_USER_PAYMENT_SUCCESS";
export const GET_USER_PAYMENT_FAIL = "GET_USER_PAYMENT_FAIL";

export const EDIT_USER_PAYMENT_REQUEST = "EDIT_USER_PAYMENT_REQUEST";
export const EDIT_USER_PAYMENT_SUCCESS = "EDIT_USER_PAYMENT_SUCCESS";
export const EDIT_USER_PAYMENT_FAIL = "EDIT_USER_PAYMENT_FAIL";
