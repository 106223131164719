import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";

//Components
import { verifyOTP } from "../../redux/methods/authMethods";

export default function OTP() {
  const [otp, setOtp] = useState(null);

  const dispatch = useDispatch();
  const { loading, success, errors } = useSelector(
    (state) => state.VerifyOTPReducer
  );
  const navigate = useNavigate();
  const { email } = useParams();

  //Displaying errors
  useEffect(() => {
    if (errors.length > 0) {
      errors.map((err) => toast.error(err.msg));
    }
  }, [errors]);

  //Display Success
  useEffect(() => {
    if (success) {
      toast.success("OTP Verified");
      navigate(`/reset-password/${email}`);
    }
  }, [success, navigate, email]);

  //Functions
  const handleVerifyOTP = (e) => {
    e.preventDefault();
    dispatch(verifyOTP(email, otp));
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-100">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Verify OTP
            </h1>
            <p className="w-full text-gray-400 my-0 py-0 font-medium font-lato">
              Please enter the code below to reset your password. Check your
              inbox and spam folder for the email.
            </p>

            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <label
                  htmlFor="otp"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your OTP
                </label>
                <input
                  type="text"
                  name="otp"
                  id="otp"
                  className="bg-gray-50 border border-gray-400 text-gray-900 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-lg block w-full p-2.5"
                  placeholder="Enter your OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              <Button
                className="mt-4 bg-blue-400 text-white"
                block
                size="large"
                htmlType={"submit"}
                loading={loading}
                onClick={handleVerifyOTP}
              >
                Verify
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
