import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  UserSignupReducer,
  UserLoginReducer,
  UserStatisticsReducer,
  ForgotPasswordReducer,
  VerifyOTPReducer,
  ResetPasswordReducer,
} from "./reducers/authReducer";
import {
  GetUserInfoReducer,
  EditUserInfoReducer,
  ChangePasswordReducer,
  RefLogsReducer,
} from "./reducers/userReducers";
import { GetSubscriptionsReducer } from "./reducers/subscriptionReducers";
import { GetBlogsReducer } from "./reducers/blogReducers";
import {
  GetAdminPaymentReducer,
  CreateUserPaymentReducer,
  EditUserPaymentReducer,
  GetUserPaymentReducer,
} from "./reducers/paymentReducers";
import {
  CreateMembershipReducer,
  GetMembershipsReducer,
} from "./reducers/membershipReducers";
import {
  CreateWithdrawReducer,
  GetWithdrawHistoryReducer,
  GetWithdrawProofReducer,
} from "./reducers/withdrawReducers";
import { GetPackagesReducer } from "./reducers/packageReducer";
import { GetAdsReducer, SubmitAdReducer } from "./reducers/adsReducers";
import {
  GetNotificationsReducer,
  ChangeNotificationStatusReducer,
} from "./reducers/notificationReducers";

const rootReducers = combineReducers({
  UserSignupReducer,
  UserLoginReducer,
  UserStatisticsReducer,
  ForgotPasswordReducer,
  VerifyOTPReducer,
  ResetPasswordReducer,

  GetUserInfoReducer,
  EditUserInfoReducer,
  ChangePasswordReducer,
  RefLogsReducer,

  GetSubscriptionsReducer,

  GetBlogsReducer,

  GetAdminPaymentReducer,
  CreateUserPaymentReducer,
  EditUserPaymentReducer,
  GetUserPaymentReducer,

  CreateMembershipReducer,
  GetMembershipsReducer,

  CreateWithdrawReducer,
  GetWithdrawHistoryReducer,
  GetWithdrawProofReducer,

  GetPackagesReducer,

  GetAdsReducer,
  SubmitAdReducer,

  GetNotificationsReducer,
  ChangeNotificationStatusReducer,
});

const middlewares = [thunkMiddleware];
const Store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default Store;
