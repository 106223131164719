//Get User Info
export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAIL = "GET_USER_INFO_FAIL";

//Edit User Info
export const EDIT_USER_INFO_REQUEST = "EDIT_USER_INFO_REQUEST";
export const EDIT_USER_INFO_SUCCESS = "EDIT_USER_INFO_SUCCESS";
export const EDIT_USER_INFO_FAIL = "EDIT_USER_INFO_FAIL";

//Change Password
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

//Ref logs
export const GET_REF_LOGS_REQUEST = "GET_REF_LOGS_REQUEST";
export const GET_REF_LOGS_SUCCESS = "GET_REF_LOGS_SUCCESS";
export const GET_REF_LOGS_FAIL = "GET_REF_LOGS_FAIL";

