import React from "react";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";

const Shopping = () => {
  // Define the animation variants
  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="">
      <Navbar />
      <div className="mx-auto px-5 max-w-[670px] lg:max-w-[800px] xl:max-w-[1150px] 2xl:max-w-[1300px] h-[30vh]">
        <div className="flex justify-start items-center h-[30vh]">
          <motion.h1
            className="xl:text-6xl lg:text-4xl text-3xl font-extrabold"
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ duration: 0.5 }} // Adjust duration as needed
          >
            Online Shopping
          </motion.h1>
        </div>
        <motion.p
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.5, delay: 0.2 }} // Adjust delay and duration as needed
        >
          An online shopping mall, also known as an e-mall or virtual shopping
          center, is a website or platform that aggregates multiple online
          stores or merchants, allowing consumers to browse and purchase
          products from various retailers in one place.
        </motion.p>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h1 className="font-extrabold mt-10 text-2xl lg:text-4xl">
            Types of Online Shopping Malls
          </h1>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              1. General-Purpose Malls:
            </motion.h1>
            <p className="ml-8 mt-1">
              Feature multiple stores and products (e.g., Amazon)
            </p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 0.8 }}
            >
              2. Specialty Malls:
            </motion.h1>
            <p className="ml-8 mt-1">
              Focus on specific categories (e.g., fashion, electronics)
            </p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 1.0 }}
            >
              3. Niche Malls:
            </motion.h1>
            <p className="ml-8 mt-1">
              Cater to specific interests (e.g., handmade products)
            </p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 1.2 }}
            >
              4. Regional Malls:
            </motion.h1>
            <p className="ml-8 mt-1">Focus on local products and services</p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 1.4 }}
            >
              5. Branded Malls:
            </motion.h1>
            <p className="ml-8 mt-1">Feature products from specific brands</p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 1.6 }}
            >
              6. Local Malls:
            </motion.h1>
            <p className="ml-8 mt-1">Showcase products from local businesses</p>
          </div>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.5, delay: 1.8 }}
        >
          <h1 className="font-extrabold mt-10 text-2xl lg:text-4xl">
            Benefits of Online Shopping Malls
          </h1>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 2.0 }}
            >
              1. Convenience:
            </motion.h1>
            <p className="ml-8 mt-1">One-stop shopping experiences</p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 2.2 }}
            >
              2. Variety:
            </motion.h1>
            <p className="ml-8 mt-1">Access to multiple products and brands</p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 2.4 }}
            >
              3. Competitive Prices:
            </motion.h1>
            <p className="ml-8 mt-1">Easy price comparisons</p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 2.6 }}
            >
              4. Time-Saving:
            </motion.h1>
            <p className="ml-8 mt-1">No need to visit individual websites</p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 2.8 }}
            >
              5. Secure Transactions:
            </motion.h1>
            <p className="ml-8 mt-1">Centralized payment processing</p>
          </div>
        </motion.div>

        {/* Online shopping works */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.5, delay: 3.0 }}
          className="py-9"
        >
          <h1 className="font-extrabold mt-10 text-2xl lg:text-4xl">
            How Online Shopping Malls Work
          </h1>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 3.2 }}
            >
              1. Merchant Partnership:
            </motion.h1>
            <p className="ml-8 mt-1">
              Merchants partner with the online mall platform
            </p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 3.4 }}
            >
              2. Virtual Storefronts:
            </motion.h1>
            <p className="ml-8 mt-1">
              Merchants create and customize storefronts
            </p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 3.6 }}
            >
              3. Centralized Shopping Cart:
            </motion.h1>
            <p className="ml-8 mt-1">
              Customers add products from various stores to a single cart
            </p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 3.8 }}
            >
              4. Payment Processing:
            </motion.h1>
            <p className="ml-8 mt-1">
              Secure payments through the mall platform
            </p>
          </div>
          <div className="mt-4 flex flex-col">
            <motion.h1
              className="font-extrabold lg:text-2xl"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5, delay: 4.0 }}
            >
              5. Order Fulfillment:
            </motion.h1>
            <p className="ml-8 mt-1">
              Merchants fulfill orders and handle shipping
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Shopping;
