import {
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  EDIT_USER_INFO_FAIL,
  EDIT_USER_INFO_REQUEST,
  EDIT_USER_INFO_SUCCESS,
  GET_REF_LOGS_FAIL,
  GET_REF_LOGS_REQUEST,
  GET_REF_LOGS_SUCCESS,
  GET_USER_INFO_FAIL,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
} from "../constants/userConstants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  user: {},
  reflogs: [],
  msg: "",
};

export const GetUserInfoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_USER_INFO_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_USER_INFO_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      user: payload,
      errors: [],
    };
  } else if (type === GET_USER_INFO_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const EditUserInfoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === EDIT_USER_INFO_REQUEST) {
    return { ...state, loading: true };
  } else if (type === EDIT_USER_INFO_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      user: payload.user,
      msg: payload.msg,
      errors: [],
    };
  } else if (type === EDIT_USER_INFO_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const ChangePasswordReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === CHANGE_PASSWORD_REQUEST) {
    return { ...state, loading: true };
  } else if (type === CHANGE_PASSWORD_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      msg: payload.msg,
      errors: [],
    };
  } else if (type === CHANGE_PASSWORD_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const RefLogsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_REF_LOGS_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_REF_LOGS_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      reflogs: payload,
      errors: [],
    };
  } else if (type === GET_REF_LOGS_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};
