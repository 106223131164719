//Create Withdraw
export const CREATE_WITHDRAW_REQUEST = "CREATE_WITHDRAW_REQUEST";
export const CREATE_WITHDRAW_SUCCESS = "CREATE_WITHDRAW_SUCCESS";
export const CREATE_WITHDRAW_FAIL = "CREATE_WITHDRAW_FAIL";

//Get Withdraw History
export const GET_WITHDRAW_HISTORY_REQUEST = "GET_WITHDRAW_HISTORY_REQUEST";
export const GET_WITHDRAW_HISTORY_SUCCESS = "GET_WITHDRAW_HISTORY_SUCCESS";
export const GET_WITHDRAW_HISTORY_FAIL = "GET_WITHDRAW_HISTORY_FAIL";

//Get Withdraw Proofs
export const GET_WITHDRAW_PROOF_REQUEST = "GET_WITHDRAW_PROOF_REQUEST";
export const GET_WITHDRAW_PROOF_SUCCESS = "GET_WITHDRAW_PROOF_SUCCESS";
export const GET_WITHDRAW_PROOF_FAIL = "GET_WITHDRAW_PROOF_FAIL";
